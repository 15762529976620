import { HeaderService } from './header.service';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { CoreService } from '@web/base/core/core.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { HeaderComponent as HeaderComponentBase } from '@web/base/core/header/header.component';
import { IResponse } from '@web/project/shared/models/response.model';
import { SharedService } from '@web/project/shared/shared.service';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { SimpleSnackbarComponent } from '@web/project/shared/components/simple-snackbar/simple-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@web/project/auth/auth.service';
import { SigninService } from './dialogs/signin/signin.service';

@Component({
  selector: 'web-project-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends HeaderComponentBase implements OnInit {
  ELEMENT_TYPE_RETIRED = ConstantsProject.ELEMENT_TYPE_RETIRED;
  ELEMENT_TYPE_VOLUNTARY = ConstantsProject.ELEMENT_TYPE_VOLUNTARY;

  // specialDay;
  headerHidden;

  public OFFICE_ADDRESSES = ConstantsProject.OFFICE_ADDRESSES;

  public searchActive = false;

  constructor(
    public translate: TranslateService,
    public coreService: CoreService,
    public authService: AuthService,
    public signinService: SigninService,
    public router: Router,
    public sc: ScrollDispatcher,
    public sharedService: SharedService,
    public headerService: HeaderService,
    public snackbar: MatSnackBar,
  ) {
    super(translate, coreService, authService, signinService, router, sc);
  }

  ngOnInit() {
    super.ngOnInit();

    // Para controlar si ocultamos o no la cabecera (algunas secciones 'microsites' la ocultan)
    this.subscriptions.push(
      this.headerService.getHeaderHidden().subscribe((hidden) => {
        this.headerHidden = hidden;
      })
    );

    // this.subscriptions.push(
    //   this.coreService.templateLoaded$.subscribe((v: IResponse) => {
    //     if (v) {
    //       this.specialDay = v.result.specialDay;
    //     }
    //   })
    // );

    this.subscriptions.push(
      this.sharedService.openSigninDialog$.subscribe(() => {
        this.signinService.openDialog();
      })
    );
  }

  setLanguage(value: string) {
    this.translate.setDefaultLang(value);
    this.translate.use(value);
  }

  goToResult(result) {
    this.resultSearch = null;

    if (result.url.substr(0, 4) === 'http') {
      window.location.href = result.url;
    } else {
      this.router.navigate([result.url]);
    }
  }

  downloadCard() {
    // Si es moroso mostramos el mensaje estándar
    if (this.authService.user && this.authService.user.isDefaulter) {
      this.openSnackbar(
        this.translations['general']['problemIsDefaulter'],
        SimpleSnackbarComponent.TYPE_ERROR
      );
    } else {
      // Si el carnet no existe volvemos a llamar a la función generar
      if (
        this.authService.user.files[0].url &&
        this.authService.user.files[0].url !== 'error'
      ) {
        window.open(this.authService.user.files[0].url, '_blank');
      } else {
        this.sharedService.getCollegiateCard().subscribe(
          (res: IResponse) => {
            window.open(res.result.files[1].url, '_blank');
          },
          (err) => {
            if (err.error && err.error.result && err.error.result.message) {
              this.openSnackbar(
                err.error.result.message,
                SimpleSnackbarComponent.TYPE_ERROR
              );
            }
          }
        );
      }
    }

  }

  openSearch() {
    this.sharedService.setSearchActive(true);
  }
}
