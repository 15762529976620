import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { SectionTitleComponent as SectionTitleComponentBase } from '@web/base/shared/components/section-title/section-title.component';

@Component({
  selector: 'web-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
})
export class SectionTitleComponent extends SectionTitleComponentBase {

  @ViewChild('bar', { static: true }) bar: ElementRef;

  constructor(
    translate: TranslateService,
    public router: Router,
    public location: Location,
    private renderer: Renderer2
  ) {
    super(translate, router, location);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.restartAnimation();
      }
    });
  }

  // Resetea la animación de la barra del título tras un cambio de ruta.
  restartAnimation() {
    this.renderer.removeClass(this.bar.nativeElement, 'animate-bar');
    void this.bar.nativeElement.offsetWidth; // Esto es para forzar al navegador que recalcule los estilos del elemento (accediendo a alguna de sus propiedades).
    this.renderer.addClass(this.bar.nativeElement, 'animate-bar');
  }
}
