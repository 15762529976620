import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SigninComponent as SigninComponentBase } from '@web/base/auth/signin/signin.component';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-project-signin',
  templateUrl: './signin.component.html',
  styleUrls: [
    // './../../../base/auth/signin/signin.component.scss',
    './signin.component.scss',
  ],
})
export class SigninComponent extends SigninComponentBase implements OnInit {
  ELEMENT_TYPE_RETIRED = ConstantsProject.ELEMENT_TYPE_RETIRED;
  ELEMENT_TYPE_VOLUNTARY = ConstantsProject.ELEMENT_TYPE_VOLUNTARY;

  ngOnInit() {
    this.getTranslations(['core', 'signin']).then(() => {
      this.breadcrumbs[0].label = this.translations['signin']['title'];
      this.setBreadcrumbs();

      this.userTypes.map((t) => {
        t.text = this.translations['core']['signin'][t.name];
        return t;
      });
    });

    this.authState$ = this.store.select('auth');

    this.form = new FormGroup({
      type: new FormControl(this.userTypes[0].value),
      username: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(20),
        ])
      ),
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(60),
        ])
      ),
    });

    this.route.queryParams.subscribe((v) => {
      if (v.type) {
        // Si es un array de tipos enviamos los tipos válidos
        if (Array.isArray(v.type)) {
          const userValid = [];

          // El Set elimina los duplicados del array.
          Array.from(new Set(v.type)).map((p) => {
            userValid.push(
              this.userTypes.find((t) => t.value.toString() === p)
            );
          });
          this.userTypes = userValid;
          this.form.controls['type'].setValue(this.userTypes[0].value);
        } else {
          this.type = v.type;
          this.userTypes = this.userTypes.filter(
            (t) => t.value.toString() === this.type
          );
        }
      }
    });
  }
}
