import { publicS3Url } from './../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { BaseWebComponent } from '@web/project/shared/components/base-web/base-web.component';
import { SharedService } from '@web/project/shared/shared.service';

@Component({
  selector: 'web-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent extends BaseWebComponent implements OnInit {

  section = 'privacy';

  breadcrumbs = [
    { label: '', url: '', navigable: false }
  ];

  constructor(
    translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.getTranslations(['core']).then(() => {
      this.breadcrumbs[0].label = this.translations['core']['dataProtection'];
      this.setBreadcrumbs();
    });
  }
}
