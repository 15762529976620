import { HeaderService } from "./header/header.service";
import { NgModule } from "@angular/core";
import * as CoreModuleBase from "../../base/core/core.module";
import { CoreRoutingModule } from "./core-routing.module";

import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { TemplateComponent } from "./template/template.component";
import { MenuMobileComponent } from "./menu/menu-mobile/menu-mobile.component";
import { HomeComponent } from "./home/home.component";
import { GridContentComponent } from "./home/grid-content/grid-content.component";
import { SliderContentComponent } from "./home/slider-content/slider-content.component";
import { ListContentComponent } from "./home/list-content/list-content.component";
import { NewslettersSubscriptionComponent } from "./newsletters-subscription/newsletters-subscription.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { MenuMobileItemComponent } from "./menu/menu-mobile/menu-mobile-item/menu-mobile-item.component";
import { MenuHeaderComponent } from "./menu/menu-header/menu-header.component";
import { MenuHeaderItemComponent } from "./menu/menu-header/menu-header-item/menu-header-item.component";
import { BaseListWebService } from "../shared/components/base-list-web/base-list-web.service";
import { RememberPasswordComponent } from "./header/dialogs/remember-password/remember-password.component";
import { SigninComponent } from "./header/dialogs/signin/signin.component";
import { AuthModule } from "../auth/auth.module";
import { BannersHeaderComponent } from "./banners/banners-header/banners-header.component";
import { AuthService } from "../auth/auth.service";
import { SigninService } from "./header/dialogs/signin/signin.service";
import { RememberPasswordService } from "./header/dialogs/remember-password/remember-password.service";
import { CookiesComponent } from "./cookies/cookies.component";
import { CookiesConsentComponent } from "./cookies/cookies-consent/cookies-consent.component";
import { PopupDialogComponent } from "./popup-dialog/popup-dialog.component";
import { CookiesService } from "./cookies/cookies.service";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ShortNewsComponent } from "./home/short-news/short-news.component";
import { CoursesContentComponent } from "./home/courses-content/courses-content.component";
import { BannersCertamenesComponent } from "./banners/banners-certamenes/banners-certamenes.component";
import { BannersConveniosComponent } from "./banners/banners-convenios/banners-convenios.component";
import { LegalComponent } from "./legal/legal/legal.component";
import { PrivacyComponent } from "./legal/privacy/privacy.component";

@NgModule({
  imports: [
    AuthModule,
    CoreModuleBase.CoreModule,
    CoreRoutingModule,
    CarouselModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    TemplateComponent,
    HomeComponent,
    SliderContentComponent,
    ListContentComponent,
    GridContentComponent,
    CoursesContentComponent,
    ShortNewsComponent,
    BreadcrumbComponent,
    NewslettersSubscriptionComponent,
    MenuHeaderComponent,
    MenuHeaderItemComponent,
    MenuMobileComponent,
    MenuMobileItemComponent,
    RememberPasswordComponent,
    SigninComponent,
    BannersHeaderComponent,
    BannersCertamenesComponent,
    BannersConveniosComponent,
    CookiesComponent,
    CookiesConsentComponent,
    PopupDialogComponent,
    LegalComponent,
    PrivacyComponent
  ],
  exports: [CoreModuleBase.CoreModule, TemplateComponent, SigninComponent],
  entryComponents: [
    RememberPasswordComponent,
    SigninComponent,
    PopupDialogComponent,
  ],
  providers: [
    BaseListWebService,
    HeaderService,
    AuthService,
    SigninService,
    RememberPasswordService,
    CookiesService,
  ],
})
export class CoreModule { }
