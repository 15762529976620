import { BaseListWebService } from '@web/base/shared/components/base-list-web/base-list-web.service';
import { Component, Input } from '@angular/core';
import { BaseListWebComponent } from '@web/project/shared/components/base-list-web/base-list-web.component';
import { IBaseListWebItem } from '@web/base/shared/models/base-list-web-item.model';

@Component({
  selector: 'web-short-news',
  templateUrl: './short-news.component.html',
  styleUrls: ['./short-news.component.scss'],
  providers: [BaseListWebService],
})
export class ShortNewsComponent extends BaseListWebComponent {
  @Input() items: Array<IBaseListWebItem>;

  getData() {
    this.items.map((c) => {
      const image = c.files.find((f) => f.name === 'image');
      c.image = image && image.url ? image.url : null;
      return c;
    })
  }
}
