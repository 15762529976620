import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuHeaderComponent as MenuHeaderComponentBase } from '@web/base/core/menu/menu-header/menu-header.component';
import { MenuItem } from '../menu-item.model';
import { TranslateService } from '@ngx-translate/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { MenuService } from '@web/base/core/menu/menu.service';
import { HeaderService } from '../../header/header.service';

@Component({
  selector: 'web-project-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class MenuHeaderComponent extends MenuHeaderComponentBase implements OnInit {
  public menuHeader: MenuItem[];
  public headerHidden;

  constructor(
    public translate: TranslateService,
    public sc: ScrollDispatcher,
    public ref: ChangeDetectorRef,
    public menuService: MenuService,
    public headerService: HeaderService,
  ) {
    super(translate, sc, ref, menuService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(
      this.headerService.getHeaderHidden().subscribe((hidden) => {
        this.headerHidden = hidden;
      })
    );
  }
}
